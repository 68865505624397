const initialState = {
    company: {},
    app: {}
}

const baseData = (state = initialState, action) => {
    switch (action.type) {
        case "COMPANY_DATA":
            return state = { ...state, company: action.payload }
        case "APP_CONFIG":
            return state = { ...state, app: action.payload }
        default: return state
    }
}

export default baseData