import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/styles/css/output.css';
import 'assets/styles/css/font.css'
import 'assets/styles/scss/custom_css.scss'
import 'assets/styles/scss/theme.scss'
import 'assets/styles/scss/animation.scss'

import App from './App';
// import reportWebVitals from './reportWebVitals';
import BaseApi from './libs/baseApi'
import BaseSocket from 'libs/baseSocket';

const baseApi = new BaseApi()
global.$baseApi = baseApi.api()

global.$baseSocket = new BaseSocket()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
