import { combineReducers } from 'redux'
import loadingReducer from './loading'
import snackbarReducer from './snackbar'
import sessionReducer from './session'
import transactionReducer from './transaction'
import baseDataReducer from './baseData'

const rootReducer = combineReducers({
    loading: loadingReducer,
    snackbar: snackbarReducer,
    session : sessionReducer,
    transaction: transactionReducer,
    base: baseDataReducer
})

export default rootReducer