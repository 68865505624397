const initialState = {
    snackbarData: []
}

const snackbar = (state = initialState, action) => {
    switch (action.type) {
        case "SNACKBAR_ACTIVE":
            return state = { snackbarData: [...state.snackbarData, action.payload] }
        case "SNACKBAR_REMOVE":
            state.snackbarData.splice(-1)
            return state = { snackbarData: [...state.snackbarData] }
        default: return state
    }
}

export default snackbar