import { useCallback } from 'react'

export default function useFetch(props) {
    async function fetchData(props) {
        var response
        if (!props?.url) return null
        response = await global.$baseApi(props)
        return response
    }

    const fetchApi = useCallback(async (props) => {
        const response = await fetchData(props)
        return response
    }, []);
    
    return fetchApi
}